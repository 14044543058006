body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal !important;
}

.MuiTypography-body1 {
  font-family: 'Do Hyeon', sans-serif !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}



a {
  cursor: pointer;
}

.alert-rounded {
  border-radius: 10px !important;
}

.alert-transparent {
  color: inherit;
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.MuiListItemText-primary {
  padding-left: 1px;
}

.table-borderless > tbody > tr > td {
  padding: 0;
}

main::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;    /* width of the entire scrollbar */
}

main::-webkit-scrollbar-track {
  background: #344db6;
  border-radius: 20px;    /* color of the tracking area */
}

main::-webkit-scrollbar-thumb {
  background-color: #057fe9;
  border-radius: 20px;
}

main::-webkit-scrollbar-thumb:hover {
  background-color: #ce05e9;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: none  /* creates padding around scroll thumb */
}

.MuiPopover-paper::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

.MuiPopover-paper::-webkit-scrollbar-track {
  background: #344db6;        /* color of the tracking area */
}

.MuiPopover-paper::-webkit-scrollbar-thumb {
  background-color: #057fe9;
  border-radius: 20px;
}

.MuiPopover-paper::-webkit-scrollbar-thumb:hover {
  background-color: #ce05e9;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: none  /* creates padding around scroll thumb */
}

textarea::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

textarea::-webkit-scrollbar-track {
  background: transparent;        /* color of the tracking area */
}

textarea::-webkit-scrollbar-thumb {
  background-color: #057fe9;
  border-radius: 20px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #ce05e9;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: none  /* creates padding around scroll thumb */
}


.css-4ljt47-MenuList::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

.css-4ljt47-MenuList::-webkit-scrollbar-track {
  background: #344db6;        /* color of the tracking area */
}

.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background-color: #057fe9;
  border-radius: 20px;
}

.css-4ljt47-MenuList::-webkit-scrollbar-thumb:hover {
  background-color: #ce05e9;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: none  /* creates padding around scroll thumb */
}


.MuiPaper-root::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

.MuiPaper-root::-webkit-scrollbar-track {
  background: #344db6;        /* color of the tracking area */
}

.MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #057fe9;
  border-radius: 20px;
}

.MuiPaper-root::-webkit-scrollbar-thumb:hover {
  background-color: #ce05e9;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: none  /* creates padding around scroll thumb */
}
